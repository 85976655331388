import React, { Suspense } from "react";
//import { nanoid } from "nanoid";
import { ErrorBoundary } from "react-error-boundary";
//import "./App.css";
// import Info from "./Components/info";
import LinearIndeterminate from "./Components/loading.js";

import { BrowserRouter as Router1, Switch, Route } from "react-router-dom";

// import HomePage from "./Components/HomePage";
import RealHomePage from "./Components/RealHomePage";
//import NewPage from "./Components/newPage";
// login may also not need lazy loading
import Login from "./Components/Login";
import ContactPage from "./Components/ContactPage";
import About from "./Components/About";
//import Logout from "./Components/Logout";
import { FirebaseAuth } from "./Components/context";
import PrivateRoutes from "./Components/PrivateRoutes";

import TransitionsModal from "./Components/MaterialUIModal";
import UsageInfo from "./Components/UsageInfo.js";
import { ReRoute } from "./Components/ReRoute.js";
import Pricing from "./Components/Pricing.js";

// lazy loading for improved loading performance and is used with suspense to allow loading/fallback content
const SOCI = React.lazy(() => import("./Components/soCI.jsx"));
const TermsAndConditions = React.lazy(() =>
  import("./Components/TermsAndConditions.js")
);
const FeedBack = React.lazy(() => import("./Components/FeedBack.js"));
const Admin = React.lazy(() => import("./Components/Admin.js"));
const AdminInfo = React.lazy(() => import("./Components/AdminInfo.js"));

const BankUpdate = React.lazy(() => import("./Components/bankUpdate.js"));
const Directory = React.lazy(() => import("./Components/Directory.js"));
const UserProfile = React.lazy(() => import("./Components/UserProfile.js"));
const Signup = React.lazy(() => import("./Components/Signup.js"));
const ResetPasscode = React.lazy(() => import("./Components/ResetPasscode.js"));
const Accounts = React.lazy(() => import("./Components/Accounts.jsx"));
const AfterLoginPage = React.lazy(() => import("./Components/AfterLoginPage"));
const Tasks = React.lazy(() => import("./Components/Tasks"));
const TasksPage = React.lazy(() => import("./Components/TasksPage"));
const TasksAdded = React.lazy(() => import("./Components/TasksAdded"));
const TaskSummary = React.lazy(() => import("./Components/TaskSummary"));
const CIPCTracking = React.lazy(() => import("./Components/CIPCTracking"));
//const About = React.lazy(() => import("./Components/About"));
const SignUpAndAddUser = React.lazy(() =>
  import("./Components/signUpAndAddUser")
);
const AddClientCompany = React.lazy(() =>
  import("./Components/AddClientCompany")
);
const ProvTax = React.lazy(() => import("./Components/ProvTax"));
const Journal = React.lazy(() => import("./Components/journal"));
const SoFP = React.lazy(() => import("./Components/soFP"));
const Notes = React.lazy(() => import("./Components/notes.jsx"));
const NotesMenuPage = React.lazy(() =>
  import("./Components/NotesMenuPage.jsx")
);
const CashFlow = React.lazy(() => import("./Components/cashflow"));
const SoCE = React.lazy(() => import("./Components/soCE"));
const TBalance = React.lazy(() => import("./Components/TBalance"));
const TaxNavigation = React.lazy(() => import("./Components/TaxNavigation"));
const VatCalculation = React.lazy(() => import("./Components/VatCalculation"));
const PdfDisplay = React.lazy(() => import("./Components/PdfDisplay.js"));
const NotesMenu = React.lazy(() => import("./Components/NotesMenu.js"));
const NoteSelected = React.lazy(() => import("./Components/NoteSelected.js"));

const Cover = React.lazy(() => import("./Components/cover.jsx"));
//const AddAccount = React.lazy(() => import("./Components/AddAccount.js"));
const Ledger = React.lazy(() => import("./Components/Ledger.js"));
const AccountantsReport = React.lazy(() => import("./Components/report.jsx"));
const Dashboard = React.lazy(() => import("./Components/Dashboard.js"));
const Tutorial = React.lazy(() => import("./Components/Tutorial.js"));

const SettingsFile = React.lazy(() => import("./Components/settings.js"));
const Contents = React.lazy(() => import("./Components/contents.jsx"));
const TaxCalc = React.lazy(() => import("./Components/TaxCalc.js"));
const AddEmployee = React.lazy(() => import("./Components/AddEmployee.js"));
const ExportData = React.lazy(() => import("./Components/ExportData.js"));
const EmployerDetails = React.lazy(() =>
  import("./Components/EmployerDetails.js")
);
const AutomateAccount = React.lazy(() =>
  import("./Components/AutomateAccount.js")
);
const Payments = React.lazy(() => import("./Components/Payment.js"));
const PayrollTotal = React.lazy(() => import("./Components/PayrollTotal.js"));
const Invoices = React.lazy(() => import("./Components/Invoices.js"));
const InvoicesAdded = React.lazy(() => import("./Components/InvoicesAdded.js"));
const InvoicesPage = React.lazy(() => import("./Components/InvoicesPage.js"));
const Support = React.lazy(() => import("./Components/Support.js"));
const ShortCut = React.lazy(() => import("./Components/ShortCut.js"));
const ExtractData = React.lazy(() => import("./Components/ExtractData.js"));
const Businesses = React.lazy(() => import("./Components/Businesses.js"));
const AddBusiness = React.lazy(() => import("./Components/AddBusiness.js"));
const AddProduct = React.lazy(() => import("./Components/AddProduct.js"));
const ProductsAdded = React.lazy(() => import("./Components/ProductsAdded.js"));
const InvoiceSummary = React.lazy(() =>
  import("./Components/InvoiceSummary.js")
);
const InvoicePayment = React.lazy(() =>
  import("./Components/InvoicePayment.js")
);
const InvestecAPI = React.lazy(() => import("./Components/InvestecAPI.js"));
const BulkImport = React.lazy(() => import("./Components/BulkImport.js"));
const UploadProducts = React.lazy(() =>
  import("./Components/UploadProducts.js")
);
const Internal = React.lazy(() => import("./Components/Internal.js"));
const ErrorFallback = React.lazy(() => import("./Components/ErrorFallback.js"));

const PageNotFound = React.lazy(() => import("./Components/pagenotfound.jsx"));
// const GiveUserAccess = React.lazy(() =>
//   import("./Components/GiveUserAccess.js")
// );

function App() {
  return (
    <Router1>
      <div className="App">
        <header className="App-header">
          {/* FirebaseAuth allows private components to access functions */}
          <FirebaseAuth>
            <ReRoute>
              {/* shown when the component is loaded, as it its not already loaded as previous. */}
              <Suspense fallback={<LinearIndeterminate />}>
                <Switch>
                  <ErrorBoundary FallbackComponent={ErrorFallback}>
                    {/* RealHomePage doesnt need to be lazy loaded, as users may land on this page */}
                    <Route exact path="/" component={RealHomePage} />

                    <PrivateRoutes
                      path="/AfterLoginPage"
                      component={AfterLoginPage}
                    />

                    <Route path="/Signup" component={Signup}></Route>
                    <Route path="/ContactPage" component={ContactPage}></Route>
                    <Route
                      path="/TermsAndConditions"
                      component={TermsAndConditions}
                    ></Route>

                    <Route path="/Login">
                      {" "}
                      <Login />
                    </Route>
                    <Route path="/About" component={About} />
                    <Route path="/Pricing" component={Pricing} />
                    <Route path="/UsageInfo" component={UsageInfo} />
                    <PrivateRoutes path="/Directory" component={Directory} />
                    <PrivateRoutes
                      path="/AddClientCompany"
                      component={AddClientCompany}
                    />

                    <PrivateRoutes
                      path="/signUpAndAddUser"
                      component={SignUpAndAddUser}
                    />

                    <PrivateRoutes path="/soCI" component={SOCI} />
                    <PrivateRoutes path="/Accounts" component={Accounts} />
                    <PrivateRoutes path="/soFP" component={SoFP} />
                    <PrivateRoutes path="/soCE" component={SoCE} />
                    <PrivateRoutes path="/notes" component={Notes} />
                    <PrivateRoutes path="/cashflow" component={CashFlow} />
                    <PrivateRoutes path="/journals" component={Journal} />
                    <PrivateRoutes path="/ProvTax" component={ProvTax} />
                    <PrivateRoutes
                      path="/InvoicesPage"
                      component={InvoicesPage}
                    />
                    <PrivateRoutes path="/cover" component={Cover} />
                    <PrivateRoutes path="/Businesses" component={Businesses} />

                    <PrivateRoutes path="/PdfDisplay" component={PdfDisplay} />
                    <PrivateRoutes
                      path="/NotesMenuPage"
                      component={NotesMenuPage}
                    />

                    <PrivateRoutes path="/NotesMenu" component={NotesMenu} />
                    <PrivateRoutes
                      path="/NoteSelected"
                      component={NoteSelected}
                    />

                    <PrivateRoutes
                      path="/MaterialUIModal"
                      component={TransitionsModal}
                    />
                    <PrivateRoutes path="/TBalance" component={TBalance} />
                    <PrivateRoutes path="/Support" component={Support} />
                    <PrivateRoutes path="/ShortCut" component={ShortCut} />
                    <PrivateRoutes
                      path="/ExtractData"
                      component={ExtractData}
                    />
                    <PrivateRoutes path="/Ledger" component={Ledger} />
                    <PrivateRoutes
                      path="/TaxNavigation"
                      component={TaxNavigation}
                    />
                    <PrivateRoutes
                      path="/VatCalculation"
                      component={VatCalculation}
                    />

                    <PrivateRoutes
                      path="/report.jsx"
                      component={AccountantsReport}
                    />

                    <PrivateRoutes path="/settings" component={SettingsFile} />
                    <PrivateRoutes path="/contents.jsx" component={Contents} />
                    <PrivateRoutes
                      path="/UserProfile"
                      component={UserProfile}
                    />
                    <PrivateRoutes path="/bankUpdate" component={BankUpdate} />
                    <PrivateRoutes path="/TaxCalc" component={TaxCalc} />
                    <PrivateRoutes
                      path="/AddEmployee"
                      component={AddEmployee}
                    />
                    <PrivateRoutes path="/ExportData" component={ExportData} />
                    <PrivateRoutes
                      path="/EmployerDetails"
                      component={EmployerDetails}
                    />
                    <PrivateRoutes
                      path="/AutomateAccount"
                      component={AutomateAccount}
                    />
                    <PrivateRoutes path="/Payment" component={Payments} />
                    <PrivateRoutes
                      path="/PayrollTotal"
                      component={PayrollTotal}
                    />
                    <PrivateRoutes path="/Invoices" component={Invoices} />
                    <PrivateRoutes
                      path="/AddBusiness"
                      component={AddBusiness}
                    />
                    <PrivateRoutes path="/Tutorial" component={Tutorial} />
                    <PrivateRoutes
                      path="/InvoicesAdded"
                      component={InvoicesAdded}
                    />
                    <PrivateRoutes
                      path="/InvoiceSummary"
                      component={InvoiceSummary}
                    />
                    <PrivateRoutes path="/AddProduct" component={AddProduct} />
                    <PrivateRoutes
                      path="/ProductsAdded"
                      component={ProductsAdded}
                    />
                    <PrivateRoutes path="/Dashboard" component={Dashboard} />
                    <PrivateRoutes
                      path="/InvoicePayment"
                      component={InvoicePayment}
                    />
                    <PrivateRoutes
                      path="/InvestecAPI"
                      component={InvestecAPI}
                    />
                    <PrivateRoutes path="/BulkImport" component={BulkImport} />
                    <PrivateRoutes path="/Internal" component={Internal} />
                    <PrivateRoutes path="/Tasks" component={Tasks} />
                    <PrivateRoutes path="/TasksPage" component={TasksPage} />
                    <PrivateRoutes path="/TasksAdded" component={TasksAdded} />
                    <PrivateRoutes
                      path="/TaskSummary"
                      component={TaskSummary}
                    />
                    <PrivateRoutes
                      path="/CIPCTracking"
                      component={CIPCTracking}
                    />
                    <PrivateRoutes
                      path="/UploadProducts"
                      component={UploadProducts}
                    />
                    <Route path="/FeedBack" component={FeedBack}></Route>
                    <Route path="/Admin" component={Admin}></Route>
                    <Route path="/AdminInfo" component={AdminInfo}></Route>
                    <Route path="/ResetPasscode" component={ResetPasscode} />
                    {/* this is for pages that are not found - not in routes , etc, must be after other routes and no path is given */}
                  </ErrorBoundary>{" "}
                  <Route component={PageNotFound} />
                </Switch>
              </Suspense>
            </ReRoute>
          </FirebaseAuth>
        </header>
      </div>
    </Router1>
  );
}

export default App;
